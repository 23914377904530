import Form from "react-bootstrap/Form";
import {Button, Col, Row} from "react-bootstrap";
import React, {ChangeEvent, FormEvent, useEffect, useState} from "react";
import {updateBillingAddress} from "../../services/userService";
import {UserProfile} from "../../services/model/UserProfile";
import {Typography} from "@mui/material";
import {BillingAddress} from "../../services/model/Payments";

type Props = {
    userBillingAddress: BillingAddress,
    update?: boolean,
    setExpand?: (value: (((prevState: boolean) => boolean) | boolean)) => void,
    setUpdate?: (value: (((prevState: boolean) => boolean) | boolean)) => void
    setShowModal?: (value: (((prevState: boolean) => boolean) | boolean)) => void
}

function BillingAddressHandler(props: Props) {

    const [billingAddress, setBillingAddress] = useState<BillingAddress>(props.userBillingAddress);
    const [validated, setValidated] = useState(false);

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        setBillingAddress({
            ...billingAddress,
            [e.target.name]: e.target.value.trim()
        });
    };

    const handleSubmit = (event: FormEvent) => {
        const form = event.currentTarget;
        // @ts-ignore
        if (form.checkValidity() === false) {
        } else {
            updateBillingAddress(billingAddress).then(value => {
                if (props.setUpdate) {
                    props.setUpdate(true);
                }
                props.setExpand ? props.setExpand(false) : console.error('No setExpand function');
                props.setShowModal ? props.setShowModal(false) : console.info('closed');
            });
        }
        event.preventDefault();
        event.stopPropagation();
        setValidated(true);
    };

    return (
        <>
            <div className="border align-left"
                 style={{marginTop: '10px', color: "black", fontSize: "initial"}}>

            <Typography variant="h5">Detalii de facturare</Typography>
            <Form id="contact-us-form" noValidate validated={validated} onSubmit={handleSubmit}>
                <Form.Group className="mb-3" style={{paddingTop: '5px'}}>
                    <Row className="g-2">
                        <Col md>
                            <Form.Label>
                                Prenume *
                            </Form.Label>
                            <Form.Control
                                type="input"
                                name="firstName"
                                placeholder="Prenume"
                                defaultValue={billingAddress?.firstName}
                                onChange={handleChange}
                                required
                            />
                            <Form.Control.Feedback type="invalid">
                                Te rugăm să introduci prenumele.
                            </Form.Control.Feedback>
                        </Col>
                        <Col md>
                            <Form.Label>
                                Nume *
                            </Form.Label>
                            <Form.Control
                                type="input"
                                name="lastName"
                                placeholder="Nume"
                                defaultValue={billingAddress?.lastName}
                                onChange={handleChange}
                                required
                            />
                            <Form.Control.Feedback type="invalid">
                                Te rugăm să introduci numele.
                            </Form.Control.Feedback>
                        </Col>
                    </Row>
                </Form.Group>

                <Form.Group className="mb-3" style={{paddingTop: '5px'}}>
                    <Row className="g-2">
                        <Col md>
                            <Form.Label>
                                Email *
                            </Form.Label>
                            <Form.Control
                                type="email"
                                name="email"
                                placeholder="Email"
                                defaultValue={billingAddress?.email}
                                onChange={handleChange}
                                required
                            />
                            <Form.Control.Feedback type="invalid">
                                Te rugăm să introduci un email valid.
                            </Form.Control.Feedback>
                        </Col>
                        <Col>
                            <Form.Label>
                                Telefon *
                            </Form.Label>
                            <Form.Control
                                type="input"
                                name="phone"
                                placeholder="Telefon"
                                defaultValue={billingAddress?.phone}
                                onChange={handleChange}
                                required
                            />
                            <Form.Control.Feedback type="invalid">
                                Te rugăm să introduci un număr de telefon valid.
                            </Form.Control.Feedback>
                        </Col>
                    </Row>
                </Form.Group>

                <Form.Group className="mb-3" style={{paddingTop: '5px'}}>
                    <Row className="g-2">
                        <Col>
                            <Form.Label>
                                Țară *
                            </Form.Label>
                            <Form.Control
                                type="input"
                                name="country"
                                placeholder="Țară"
                                defaultValue={billingAddress?.country}
                                onChange={handleChange}
                                required
                            />
                            <Form.Control.Feedback type="invalid">
                                Te rugăm să introduci o țară validă.
                            </Form.Control.Feedback>
                        </Col>

                        <Col md>
                            <Form.Label>
                                Județ *
                            </Form.Label>
                            <Form.Control
                                type="input"
                                name="county"
                                placeholder="Județ"
                                defaultValue={billingAddress?.county}
                                onChange={handleChange}
                                required
                            />
                            <Form.Control.Feedback type="invalid">
                                Te rugăm să introduci un județ valid.
                            </Form.Control.Feedback>
                        </Col>

                    </Row>
                </Form.Group>

                <Form.Group className="mb-3" style={{paddingTop: '5px'}}>
                    <Row className="g-2">
                        <Col>
                            <Form.Label>
                                Oraș *
                            </Form.Label>
                            <Form.Control
                                type="input"
                                name="city"
                                placeholder="Oraș"
                                defaultValue={billingAddress?.city}
                                onChange={handleChange}
                                required
                            />
                            <Form.Control.Feedback type="invalid">
                                Te rugăm să introduci un oraș valid.
                            </Form.Control.Feedback>
                        </Col>

                        <Col md>
                            <Form.Label>
                                Adresă *
                            </Form.Label>
                            <Form.Control
                                type="input"
                                name="address"
                                placeholder="Adresă"
                                defaultValue={billingAddress?.address}
                                onChange={handleChange}
                                required
                            />
                            <Form.Control.Feedback type="invalid">
                                Te rugăm să introduci o adresă validă.
                            </Form.Control.Feedback>
                        </Col>

                        <Col>
                            <Form.Label>
                                Cod Poștal
                            </Form.Label>
                            <Form.Control
                                type="input"
                                name="postCode"
                                placeholder="Cod Poștal"
                                defaultValue={billingAddress?.postCode}
                                onChange={handleChange}
                            />
                            <Form.Control.Feedback type="invalid">
                                Te rugăm să introduci un cod poștal valid.
                            </Form.Control.Feedback>
                        </Col>
                    </Row>
                </Form.Group>

                <Form.Group style={{textAlign: 'center'}}>
                    <Row className="g-2">
                        <Col md>
                            <Button type="submit" className="button button-contact">
                                Salvează
                            </Button>
                        </Col>
                    </Row>
                </Form.Group>
            </Form>
            </div>
        </>
    );
}

export default BillingAddressHandler;
